import { Flex, Spin } from "antd";

import styles from "./Loader.module.scss";

export function FullscreenLoader({ message }: { message?: string }) {
  return (
    <Flex align="center" justify="center" className={styles.loadingWrapper}>
      <Spin size="large" />
      <div>{message}</div>
    </Flex>
  );
}
