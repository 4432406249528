import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { KEY_CULTURE } from './app/constants/localStorage';
import en from './app/assets/locales/en.json';

i18n.use(initReactI18next).init({
  lng: `${navigator.cookieEnabled ? localStorage.getItem(KEY_CULTURE) : 'en'}`,
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development',
  interpolation: { escapeValue: true },
  resources: { en },
  ns: ['lobby', 'room']
});

export default i18n;
